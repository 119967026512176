// -----------------------------------//
// HP SLIDER
// -----------------------------------//

.body_4475 #slider {
  position: relative;
  height: 100vh;
  max-height: 1080px;
  min-height: 550px;

  border-top: 5px solid #fff;

  @media only screen and (max-width: 990px) {
    border-top: 0;
  }
}


.video-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -99;

  // for top of page gradient
  &:before {
    @include before(100%, 464px);
    background: url(/i/design/gradient.png) repeat-x top left;
    top: 0;
    left: 0;
    z-index: 10;
    opacity: 0.5;
  }

  &:after {
    @include before(100%, 452px);
    background: url(/i/design/gradient-btm.png) repeat-x bottom left;
    bottom: 0;
    left: 0;
    z-index: 10;
    opacity: 0.5;
  }

}

.video-foreground,
.video-background iframe {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 750px;
  pointer-events: none;
  overflow: hidden;
}

.video-background iframe {
  height: 130%;
  top: -15%;
  left: 0;
  right: 0;
  margin: 0 auto;
  object-fit: fill;

}

@media only screen and (max-width: 700px) {
  .video-background {
    display: none;
  }
}


// -----------------------------------//
// IP SLIDER
// -----------------------------------//

#slider {
  width: 100%;
  position: relative;
  min-height: 350px;
  z-index: 1;
}

.backstretch {
  position: fixed !important;
  height: 100vh !important;

  // for top of page gradient
  &:before {
    @include before(100%, 464px);
    background: url(/i/design/gradient.png) repeat-x top left;
    top: 0;
    left: 0;
  }

  &:after {
    @include before(100%, 452px);
    background: url(/i/design/gradient-btm.png) repeat-x bottom left;
    bottom: 0;
    left: 0;
  }

  img {
    top: 0px !important;
    min-width: 100% !important;
    min-height: 100% !important;
    object-fit: cover !important;
  }
}

// -----------------------------------//
// STRAPLINE
// -----------------------------------//

.strapline {
  font-family: 'Sen', sans-serif;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100px;
  margin: 0 auto;
  text-align: center;
  color: #fff;
  font-size: 60px;
  font-weight: bold;
  letter-spacing: -2px;

  @media only screen and (max-width: 1200px) {
    font-size: 5vw;
  }

  @media only screen and (max-width: 820px) {
    font-size: 40px;
    padding: 0 15px;
  }

  @media only screen and (max-width: 630px) {
    font-size: 35px;
    bottom: 70px;
  }

  @media only screen and (max-width: 500px) {
    font-size: 30px;
  }
}