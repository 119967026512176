// &:before { @include before(100px, 100px); background: url(/i/design/) no-repeat center; top: 0; left: 0; } 

// -----------------------------------//
// FONTS
// -----------------------------------//

@import url('https://fonts.googleapis.com/css2?family=Actor&family=Sen:wght@400;700;800&display=swap');

// -----------------------------------//
// VARIABLES
// -----------------------------------//

$link-color: deepskyblue;

$h1-color: #005AFF;
$h2-color: #C7191F;
$h3-bg-color: #005AFF;
$h4-bg-color: #C7191F;

// -----------------------------------//
// BODY
// -----------------------------------//

body {
	text-align: center;
	padding: 0;
	margin: 0;
	position: relative;
	overflow-x: hidden;
	font-family: 'Actor', sans-serif;
	// font-family: 'Sen', sans-serif;
}

.body_4475 {}

// -----------------------------------//
// GLOBAL
// -----------------------------------//

.body_4475 * {
	box-sizing: border-box;
}

body a {
	color: $link-color;
	text-decoration: none;
	transition: .5s;
}

body a:hover,
body a:focus {
	text-decoration: none;
}

a img,
img a,
img,
p a img {
	border: 0;
	border-bottom: 0;
	text-decoration: none;
	vertical-align: middle;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 500;
	font-family: 'Sen', sans-serif;
}

h1 {
	font-size: 52px;
	margin: 0 0 20px 0;
	color: $h1-color;
	line-height: 1.2;
}

h2 {
	font-size: 35px;
	margin: 25px 0 20px 0;
	color: $h2-color;
	line-height: 1.3;
}

h3 {
	font-size: 20px;
	margin: 25px 0 10px 0;
	padding: 5px 10px 3px 10px;
	background: $h3-bg-color;
	color: #fff;
}

h4 {
	@extend h3;
	background-position: 10px center;
	background-repeat: no-repeat;
	padding-left: 30px;
	background-color: $h4-bg-color;
}

h4.open {
	background-image: url(/i/design/expanding-faq/minus-white.png);
}

h4.closed {
	background-image: url(/i/design/expanding-faq/plus-white.png);
}

h5 {
	font-size: 12px;
}

h6,
small {
	font-size: 10px;
}

h1+h2 {
	margin-top: 0;
	border: 0;
	padding: 0;
}

h1+p,
h2+p,
h3+p {
	margin-top: 0;
}

p {
	margin: 12px 0;
	padding: 0;
}

li {
	margin: 2px 0;
	padding: 0 0 0 23px;
	background: url(/i/icons/bullet_black.png) no-repeat top 6px left 3px;
}

hr {
	height: 1px;
	border-width: 0;
	color: #f2f2f2;
	background-color: #f2f2f2;
}

// -----------------------------------//
// WRAPPER
// -----------------------------------//

#wrapper {
	margin: 0 auto;
	text-align: center;
	max-width: 2000px;
	overflow: hidden;
}

.wrapper {
	width: 990px;
	margin: 0 auto;
	height: 100%;
	@extend .clearfix;
	position: relative;
}

.wrapper-inside {
	width: 1500px;
	max-width: 90%;
	margin: 0 auto;
	@extend .clearfix;
	position: relative;
}

// fix home page wrapper called in t.asp
.body_4475 .wrapper-inside {
	width: 100%;
	max-width: 100%;
	padding: 0;
}

// -----------------------------------//
// HEADER
// -----------------------------------//

header {
	padding: 0;
}

header>.wrapper {
	padding: 0;
	position: relative;
	z-index: 60;
	width: 90%;

	@media only screen and (max-width: 990px) {
		width: 95%;
	}
}

header h1 {
	margin: 0;
	background: none;
}

#logo-index {
	margin: 0;
	padding: 0;
	position: absolute;
	top: 60px;
	width: 100%;

	@media only screen and (max-width: 1060px) {
		top: 80px;
	}

	@media only screen and (max-width: 990px) {
		margin: 0 auto;
		top: 120px;
	}

	a {
		span {}

		img#logo {
			@media only screen and (max-width: 990px) {
				margin: 0 auto;
			}
		}
	}
}

// -----------------------------------//
// SOCIAL DOCK
// -----------------------------------//

#social-dock {
	position: absolute;
	right: 0;
	top: 60px;
	z-index: 99;

	@media only screen and (max-width: 1060px) {
		top: 80px;
	}

	@media only screen and (max-width: 990px) {
		top: 110px;
	}

	@media only screen and (max-width: 350px) {
		scale: 0.8;
		top: 80px;
		right: -10px;
	}

	li {
		display: inline-block;
		@include box(48px);
		padding: 0;
		background: none;
		margin: 0px;
		transition: 0.4s;

		@media only screen and (max-width: 990px) {
			display: block;
			margin: 0 0 5px 0;
		}

		&:hover {
			translate: 0 -3px;
		}

		a {
			display: block;
			@include box(100%);
		}

		#facebook-head {
			@include box(48px);
			overflow: hidden;
			z-index: 99;
			background: url(/i/design/facebook.png) no-repeat center;
		}

		//	#twitter-head { @include box(48px); overflow: hidden; z-index: 99; background: url(/i/design/tw-ic.png) no-repeat center; } 
		//	#instagram-head { @include box(48px); overflow: hidden; z-index: 99; background: url(/i/design/in-ic.png) no-repeat center; } 
	}
}

// -----------------------------------//
// GOOGLE TRANSLATE WIDGET
// -----------------------------------//

#google_translate_element {
	opacity: 0;
}

.translate {
	@include box(48px);
	overflow: hidden;
	background: url(/i/design/translate.png) no-repeat center;
	z-index: 99;
}

.goog-te-gadget .goog-te-combo {
	padding: 10px;
}

// -----------------------------------//
// SEARCH FORM
// -----------------------------------//

a#FS {
	@include box(48px);
	background: url(/i/design/search.png) no-repeat center;
	padding: 0px;
	transition: all .3s ease;
	cursor: pointer;
}

/* Search Style */
#search {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.9);
	transition: all 0.5s ease-in-out;
	transform: translate(0px, -100%) scale(0, 0);
	opacity: 0;
	display: none;
}

#search.open {
	transform: translate(0px, 0px) scale(1, 1);
	opacity: 1;
	z-index: 9999999;
	display: block;
}

#search input[type="search"] {
	position: absolute;
	top: 50%;
	left: 0;
	margin-top: -51px;
	width: 60%;
	margin-left: 20%;
	color: rgb(255, 255, 255);
	background: transparent;
	border-left: 0px solid transparent;
	border-right: 0px solid transparent;
	font-size: 40px;
	font-weight: 300;
	text-align: center;
	outline: none;
	padding: 10px;
}

// -----------------------------------//
// SEARCH RESULTS
// -----------------------------------//

$search-highlight-background-color: #005AFF;
$search-highlight-color: #fff;
$search-highlight-font-weight: bold;
$search-highlight-font-size: 15px;
$search-odd-tr-background: #ececec;

.search-subject-box {
	display: inline-block;
	background: #f1f1f1;
	padding: 5px 15px;
	font-size: 16px;
	border-radius: 4px;
	border: 1px solid #ccc;
	text-transform: uppercase;
}

.search-numbers {
	text-transform: uppercase;
	font-weight: bold;
	font-size: 12px;

	span {
		background: #f1f1f1;
		padding: 3px 8px;
		margin: 0 3px;
		border-radius: 2px;
		border: 1px solid #ccc;
	}
}

// -----------------------------------//
// MAIN HOLDER
// -----------------------------------//

#mainholder {
	width: 100%;
	margin: 0px auto;
	padding: 30px 0 30px 0;
	clear: both;
	text-align: left;
	@extend .clearfix;
	position: relative;
	z-index: 10;
}

// home page alternate
.body_4475 #mainholder {
	padding: 0;
	margin: 0;
}

// -----------------------------------//
// WELCOME SEC
// -----------------------------------//

#welcome-sec {
	padding: 100px 0;
	background: #C81920 url(/i/design/welcome-bg.jpg) top no-repeat;
	position: relative;

	@media only screen and (max-width: 1370px) {
		padding: 60px 0;
	}

	.wrapper {
		display: flex;
		width: 1250px;

		@media only screen and (max-width: 1370px) {
			width: 95%;
			align-items: center;
		}

		@media only screen and (max-width: 990px) {
			flex-direction: column-reverse;
		}


		.left {
			flex-shrink: 0;
			margin: 0 100px 0 0;

			@media only screen and (max-width: 1370px) {
				flex-shrink: 0.3;
				margin: 0 60px 0 0;
			}

			@media only screen and (max-width: 990px) {
				margin: 0;
			}

			img {
				box-shadow: 0px 7px 27px 0px rgba(0, 0, 0, 0.33);
				border-radius: 20px;
				transition: 0.4s;

				@media only screen and (max-width: 990px) {
					max-width: 400px;
				}
			}

		}


		.right {

			@media only screen and (max-width: 990px) {
				text-align: center;
				margin: 0 0 50px 0;

			}

			h2 {
				color: #fff;
				font-size: 55px;
				line-height: 1;
				font-family: 'Actor', sans-serif;
				font-weight: 300;

				@media only screen and (max-width: 990px) {
					font-size: 40px;
				}

				span {
					display: block;
					color: #E8DC24;
					text-transform: uppercase;
					font-size: 32px;

					@media only screen and (max-width: 990px) {
						font-size: 30px;
					}
				}
			}

			p {
				color: #fff;
				font-size: 20px;
				line-height: 30px;

				@media only screen and (max-width: 990px) {
					line-height: normal;
				}

				&:nth-of-type(1) {
					font-size: 25px;
					padding: 20px 0 15px 0;

					@media only screen and (max-width: 990px) {
						font-size: 20px;
						padding: 0;
					}
				}

				&:nth-last-of-type(1) {
					@media only screen and (max-width: 990px) {
						margin: 0 0 30px 0;
					}
				}
			}

			a.more {
				display: block;
				background: #fff;
				color: #409C59;
				text-transform: uppercase;
				font-size: 16px;
				line-height: 65px;
				width: 224px;
				font-weight: bold;
				text-align: center;
				border-radius: 32px;
				margin: 40px 0 0 0;
				transition: 0.4s;

				@media only screen and (max-width: 990px) {
					margin: 0 auto;
				}

				&:hover {
					background: none;
					box-shadow: inset 0px 0px 0px 3px #fff, 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
					color: #fff;
				}
			}
		}
	}

}

// -----------------------------------//
// NEWS SEC
// -----------------------------------//

#news-sec {
	padding: 100px 0;
	background: url(/i/design/news-bg.png) top;
	position: relative;
	border-bottom: 10px solid #FFD440;

	@media only screen and (max-width: 990px) {
		padding: 60px 0;
	}

	.wrapper {

		@media only screen and (max-width: 1120px) {
			width: 90%;
		}

		h2 {
			color: #fff;
			text-transform: uppercase;
			font-weight: 700;
			font-size: 84px;
			text-align: center;
			margin: 0;
			line-height: 1;

			@media only screen and (max-width: 990px) {
				font-size: 70px;
			}

			@media only screen and (max-width: 650px) {
				font-size: 60px;
			}
		}

		#SideHeadingNews {

			ul.SideList {
				display: flex;
				justify-content: space-between;
				margin: 70px 0;

				@media only screen and (max-width: 990px) {
					flex-wrap: wrap;
					justify-content: center;
					gap: 40px;
				}

				li {

					&:hover {
						a {
							p.title {
								text-decoration: underline dotted;
							}
						}
					}

					a {
						color: #fff;
						display: flex;
						flex-direction: column;
						width: 300px;

						@media only screen and (max-width: 990px) {
							text-align: center;
						}

						p.date {
							font-size: 46px;
							text-transform: uppercase;
							line-height: 75px;
							margin: 0 0 40px 0;


							span {
								font-size: 96px;
							}
						}

						p.title {
							font-size: 18px;
							line-height: 25px;
							font-weight: lighter;
						}
					}
				}
			}
		}

		.btn-wrap {
			display: flex;
			justify-content: center;
			gap: 20px;

			@media only screen and (max-width: 650px) {
				flex-direction: column;
				align-items: center;
			}

			a.more {
				display: inline-block;
				background: #fff;
				color: #409C59;
				text-transform: uppercase;
				font-size: 16px;
				line-height: 65px;
				width: 224px;
				font-weight: bold;
				text-align: center;
				border-radius: 32px;
				transition: 0.4s;

				&:hover {
					background: none;
					box-shadow: inset 0px 0px 0px 3px #fff, 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
					color: #fff;
				}
			}


		}
	}
}

// -----------------------------------//
// HOUSE POINTS
// -----------------------------------//

#house-points {
	padding: 100px 0;
	background: url(/i/design/house-points-bg.jpg) top;
	position: relative;

	@media only screen and (max-width: 990px) {
		padding: 60px 0;
		background: #fff;
	}

	.wrapper {

		@media only screen and (max-width: 1120px) {
			width: 90%;
		}

		h2 {
			color: #D7181F;
			text-transform: uppercase;
			font-weight: 700;
			font-size: 84px;
			text-align: center;
			margin: 0;
			line-height: 1;

			@media only screen and (max-width: 990px) {
				font-size: 70px;
			}

			@media only screen and (max-width: 650px) {
				font-size: 60px;
			}
		}

		.house-points-container {
			display: flex;
			margin: 100px 0 0 0;

			@media only screen and (max-width: 1120px) {
				flex-wrap: wrap;
				justify-content: center;
				gap: 20px;

			}

			li {
				position: relative;
				width: calc(100% /4);
				min-width: 250px;
				height: 340px;
				background: none;
				padding: 0;
				margin: 0;
				text-align: center;

				&:before {
					content: "";
					position: absolute;
					background: url(/i/design/shield.png) no-repeat center center;
					height: 237px;
					width: 199px;
					left: 50%;
					transform: translateX(-50%);
					top: 80px;
				}

				&:nth-of-type(1) {
					color: #D7181F;

					&:before {
						filter: invert(15%) sepia(68%) saturate(5119%) hue-rotate(349deg) brightness(94%) contrast(96%);
					}
				}

				&:nth-of-type(2) {
					color: #FFD440;

					&:before {
						filter: invert(94%) sepia(49%) saturate(2917%) hue-rotate(321deg) brightness(101%) contrast(105%);
					}
				}

				&:nth-of-type(3) {
					color: #005AFF;

					&:before {
						filter: invert(28%) sepia(65%) saturate(7000%) hue-rotate(217deg) brightness(101%) contrast(109%);
					}
				}

				&:nth-of-type(4) {
					color: #2AA64C;

					&:before {
						filter: invert(49%) sepia(72%) saturate(509%) hue-rotate(84deg) brightness(94%) contrast(82%);
					}
				}

				p,
				span {
					position: relative;
				}

				p {
					position: relative;
					font-size: 30px;
					line-height: 1;
					font-family: 'Sen', sans-serif;
					font-weight: bold;
					margin: 0 0 100px 0;
				}

				span {
					font-size: 66px;
					display: block;
				}
			}
		}

	}
}

// -----------------------------------//
// PARALLAX
// -----------------------------------//

#parallax {
	position: relative;
	box-shadow: inset 0px 0px 65px 0px rgba(0, 0, 0, 0.004);
	height: 666px;

	@media only screen and (max-width: 990px) {
		display: none;
	}
}

// -----------------------------------//
// CONTENT COLUMNS
// -----------------------------------//

//Content 1
.Content1Column {
	width: 100%;
	max-width: 1300px;
	margin: 0 auto 50px;
	padding: 30px 50px;
	min-height: 350px;
	text-align: left;
	background: rgba(255, 255, 255, .9);
	@extend .clearfix;
	border-radius: 20px;

	img {
		max-width: 100%;
		height: auto;
	}
}

//Content 2
.Content2Column {
	width: calc(92% - 350px);
	margin: 0 0 50px 8%;
	padding: 30px 50px;
	min-height: 350px;
	text-align: left;
	display: inline-block;
	float: left;
	background: rgba(255, 255, 255, .9);
	@extend .clearfix;
	border-radius: 20px;

	img {
		max-width: 100%;
		height: auto;
	}
}

//Content 3
.Content3Column {
	width: 430px;
	margin: 0;
	padding: 0;
	float: left;
	min-height: 350px;
	text-align: left;
	border-radius: 20px;

	img {
		max-width: 100%;
		height: auto;
	}
}

#mainholder {

	tr,
	td {
		border: 1px solid #BFBFBF;
	}

	td {
		padding: 5px;
	}

	.tablenoborderpadded {
		border: 0 !important;

		tr,
		td {
			border: 0px !important;
		}
	}
}

.HomeAlert .Content1Column {
	float: none;
	margin: 0 auto 50px;
}

// -----------------------------------//
// COLUMN LEFT & COLUMN RIGHT
// -----------------------------------//

.ColumnLeft {
	width: 250px;
	margin: 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	float: left;
}

.ColumnRight {
	width: 300px;
	margin: 0 20px 0 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	display: inline-block;
	float: right;
	box-sizing: border-box;
}

.ColumnLeft ul,
.ColumnRight ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.ColumnLeft nav ul a:link,
.ColumnRight nav ul a:visited,
.ColumnRight nav ul a:link,
.ColumnRight nav ul a:visited {
	display: block;
}

.ColumnLeft li {
	border-bottom: 1px solid #EEE;
}

// Hack to fix IE/Win's broken rendering of block-level anchors in lists
html>body .ColumnLeft li {
	border-bottom: none;
}

// Fix for browsers that don't need the hack

// -----------------------------------//
// SIDE COLUMN > LIST SECTIONS
// -----------------------------------//

// Container
.SideHeading {
	margin: 0;
	padding: 0;
	text-align: center;
}

// List
ul.SideList {
	margin: 0;
	padding: 0;
	border: 0;
	list-style: none;
	text-align: left;
}

ul.SideList li {
	margin: 0;
	padding: 0;
	background: none;
}

ul.SideList li a {
	margin: 0;
	padding: 0px;
	color: #000;
	transition: .5s;
	font-weight: normal;
	display: block;
}

ul.SideList li a:hover {
	transition: .5s;
	text-decoration: none;
}

ul.SideList li a.child {
	background: url(/i/design/svg/ic-related-pages.svg) left 20px center no-repeat;
	padding-left: 40px !important;
}

// Date and Title
ul.SideList li p {
	margin: 0;
	padding: 0;
}

ul.SideList p.date {
	float: left;
	text-align: center;
	font-size: 20px;
	line-height: 130%;
	transition: .5s;

	// Span = number
	span {
		display: block;
		transition: .5s;
	}
}

ul.SideList p.title {
	float: right;
	width: 100%;
	font-size: 16px;
	transition: .5s;

	// Truncate
	span {
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		height: auto !important;
	}
}

// More links
.SideHeading a.more {
	display: inline-block;
	font-size: 18px;
	padding: 0px;
	transition: .5s;

	&:hover {
		transition: .5s;
		text-decoration: none;
	}
}

// -----------------------------------//
// RELATED PAGES
// -----------------------------------//

#SideHeadingPages {
	position: relative;
	overflow: hidden;
	z-index: 1;
	padding: 5%;
	background: rgba(#409C59, 0.95);
	border-radius: 20px;

	h2 {
		display: block;
		text-align: center;
		font-size: 28px;
		padding: 20px 0;
		margin: 0;
		color: #fff;
	}

	ul.SideList {
		padding: 0 0 30px 0;

		li {
			a {
				padding: 7px 20px;
				font-size: 18px;
				color: #fff;

				&:hover {
					background-color: rgba(255, 255, 255, .1);
				}
			}
		}
	}
}

// -----------------------------------//
// HOME PAGE ALERT
// -----------------------------------//

//Modal
.modal-home {
	background: rgba(0, 0, 0, .4);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 8000;

	.message-modal {
		position: absolute;
		top: calc(50% - 300px);
		left: calc(50% - 465px - 40px - 10px);
		width: 930px;
		height: auto;
		margin: 0 30px;
		padding: 20px;
		background: rgba(255, 255, 255, .9);

		.modal-content {
			background: rgba(255, 255, 255, .9);
			background-position: -120px 50px;
			box-shadow: inset 0 0 0 1px #fff;
			display: inline-block;
			width: 100%;
			height: 100%;
			text-align: center;

			.modal-text {
				display: block;
				padding: 45px;

				p {
					margin: 0;
				}
			}

			a.close-modal {
				position: absolute;
				top: 31px;
				right: 31px;
			}
		}
	}
}

.hide-modal {
	display: none !important;
}

// -----------------------------------//
// AWARDS
// -----------------------------------//

#awards {
	width: 100%;
	overflow: hidden;
	background: #fff;
	padding: 20px 0;
	position: relative;
	z-index: 10;

	.CMSawards {
		overflow: hidden;
		width: 100%;
		height: 100%;
		margin: 0 auto !important;
		background: none !important;

		ul {
			margin: 0;

			li {
				background: none;
				padding: 0 5px;
				margin: 0 10px;
				display: inline-block;

				img {
					max-height: 70px;
					transform: none !important;
					display: inline-block;
				}
			}
		}
	}
}

// -----------------------------------//
// SEASONAL FX
// -----------------------------------//

.seasonaleffect {
	z-index: 999 !important;
}

// -----------------------------------//
// FOOTER
// -----------------------------------//

footer {
	margin: 0 auto;
	clear: both;
	background: #C7191F url(/i/design/footer-bg.jpg) no-repeat;
	padding: 90px 0 50px 0;
	position: relative;
	z-index: 10;

	.wrapper {
		text-align: left;
		font-size: 20px;

		@media only screen and (max-width: 1070px) {
			text-align: center;
		}

		@media only screen and (max-width: 590px) {
			font-size: 18px;
		}
	}

	p,
	a[href^="mailto:"] {
		margin: 0;
		color: #fff;
	}

}

h2.footer-heading {
	padding: 0;
	margin: 0;
	color: #FFD440;
	font-size: 28px;
	text-transform: uppercase;
	font-family: 'Actor', sans-serif;

	@media only screen and (max-width: 590px) {
		font-size: 25px;
	}
}

p.address {}

p.telephone {
	margin: 0 0 15px 0;
}

p.head {
	margin: 15px 0;
}

footer a[href^="mailto:"] {
	display: block;
	padding: 0 0 80px 0;

	img {
		display: inline-block;
		margin: 0 7px 0 0;
	}
}

.map {
	position: absolute;
	right: 100px;
	top: 50%;
	transform: translateY(-50%);

	@media only screen and (max-width: 1640px) {
		right: 30px;
	}

	@media only screen and (max-width: 1070px) {
		display: none;
	}

	&:after {
		position: absolute;
		content: "";
		background: url(/i/design/map-arrow.png) no-repeat;
		height: 274px;
		width: 220px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		pointer-events: none;
		animation: location 3s ease 0s infinite normal both;

		@media only screen and (max-width: 1640px) {
			scale: 0.8;
			left: 47%;
			top: 48%;
		}



		@keyframes location {
			0% {
				opacity: 1;
				top: 50%;

				@media only screen and (max-width: 1640px) {
					top: 48%;
				}
			}

			50% {
				translate: 0 24px;
			}

			100% {
				top: 50%;

				@media only screen and (max-width: 1640px) {
					top: 48%;
				}
			}
		}
	}

	img {
		border-radius: 20px;
		box-shadow: 0px 7px 27px 0px rgba(0, 0, 0, 0.33);

		@media only screen and (max-width: 1640px) {
			width: 560px;
		}

	}
}

// -----------------------------------//
// SUB-FOOTER
// -----------------------------------//

.sub {
	font-size: 12px;

	a {
		color: #fff;
	}

	p {
		display: inline-block;

		@media only screen and (max-width: 590px) {
			display: block;
			margin: 0 0 12px 0;

		}
	}

	#credit {

		img {
			display: inline-block;
			margin: -5px 0 0 0;

			@media only screen and (max-width: 590px) {
				margin: 0;
			}
		}
	}

	#copyright {
		margin: 0 40px 0 0;

		@media only screen and (max-width: 590px) {
			margin: 0;

		}
	}
}

// -----------------------------------//
// TOP BUTTON
// -----------------------------------//

.top-btn {
	position: fixed;
	z-index: 99999;
	bottom: 50px;
	right: 30px;
	display: block;
	width: 45px;
	height: 45px;
	background: rgba(0, 0, 0, .3);
	border-radius: 50%;
	color: #fff;
	font-size: 25px;
	text-align: center;
	opacity: 0;
	transform: scale(0);
	transition: .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);

	@media only screen and (max-width: 1199px) {
		display: none;
	}
}

.show-top-btn {
	opacity: 1;
	transform: scale(1);
}

// -----------------------------------//
// CMS CONTENT: TABLES
// -----------------------------------//

$table-highlight-color: darkblue;
$table-highlight-background-color: lightblue;
$table-highlight-font-weight: bold;
$table-border-color: #222;

// -----------------------------------//
// CMS CONTENT: BLOCKQUOTES
// -----------------------------------//

blockquote p {
	text-align: center;
}

$blockquote-background-color: #fff;
$blockquote-border-color: #eeeeee;
$blockquote-color: #222;
$blockquote-speech-mark-color: #222;
$blockquote-font-size: 1em;